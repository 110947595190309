import { PatientBase } from "../../graph/patients/patient-base";
import { PatientSmileGoalBase } from "../patient_smile_goal/patient-smile-goal-base";

export enum E_Visit_Action_Type {
  MEDICAL_HISTORY = "medical_history",
  GP17_PART_1 = "gp17_part1",
  GP17_PART_2 = "gp17_part2",
}

export enum E_Visit_Action_Status {
  PENDING = "pending",
  COMPLETED = "completed",
}

export class VisitsCreateError extends Error {
  constructor(public status: string, public code: string, message: string) {
    super(message);
  }
}

export interface I_VisitActionMetaDataTreatmentPlan {
  completed_date: string;
  total_cost: number;
  appointment_id: number;
}

export interface I_VisitActionMetaDataAppointement {
  appointment_sid: number;
  practitioner_name: string;
}

export class VisitActionMetaDataBase {
  medical_history_id?: string | null;
  exemption_id?: string | null;
  treatment_plans?: Array<I_VisitActionMetaDataTreatmentPlan>;
  form_type?: E_Visit_Action_Type;
  patient_exemption_code?: string;
  appointments?: Array<I_VisitActionMetaDataAppointement>;
  smile_goal?: PatientSmileGoalBase;
}

export class VisitActionBase {
  public action_type: E_Visit_Action_Type;
  public metadata: VisitActionMetaDataBase;
  public status: E_Visit_Action_Status;
  public required: boolean;
}

export class VisitBase {
  public id: string;
  public practice_id: number;
  public site_id: string;
  public date: string;
  public state: string;
  public actions: Array<VisitActionBase>;
  public patient: PatientBase;
  public appointments: Array<VisitAppointmentBase>;
}

export class VisitAppointmentBase {
  patient_id: number;
  booked_via_api: boolean;
  created_at: string;
  duration: number;
  finish_time: string;
  id: number;
  patient_image_url: string;
  patient_name: string;
  payment_plan_id: number;
  pending_at: string;
  practitioner_id: number;
  practitioner_site_id: string;
  reason: string;
  start_time: string;
  state: string;
  updated_at: string;
  user_id: number;
}

export interface I_NewVisit {
  date_of_birth: string;
  patient_identifier: string;
  site_id: string;
  first_name?: string;
  patient_id?: string;
}

export interface I_UpdateVisit {
  id: string;
  state: E_Visit_State;
}

export enum E_Visit_State {
  ARRIVING = "arriving",
  ARRIVED = "arrived",
  DEPARTED = "departed",
  CANCELLED = "cancelled",
}
